export const Badge = ({
  variant = 'default',
  children,
  className = ""
}) => {
  const variants = {
    default: "bg-gray-100/80 text-gray-800",
    success: "bg-green-100/80 text-green-800",
    warning: "bg-amber-100/80 text-amber-800",
    error: "bg-red-100/80 text-red-800",
    ai: "bg-gradient-to-r from-purple-100/80 to-indigo-100/80 text-indigo-800"
  }

  return (
    <span className={`
      ${variants[variant]}
      backdrop-blur-sm
      shadow-[0_2px_8px_rgb(0,0,0,0.04)]
      dark:shadow-[0_2px_8px_rgb(0,0,0,0.1)]
      inline-flex items-center
      rounded-full
      px-3 py-1
      text-sm
      font-light
      ${className}
    `}>
      {children}
    </span>
  )
}