import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import ClosedBetaForm from '../components/ClosedBetaForm';

import {
  SparklesIcon,
  ChartBarIcon,
  BoltIcon,
  CloudArrowUpIcon,
  CogIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline';

const features = [
  {
    icon: SparklesIcon,
    title: "AI-Powered Business Intelligence",
    description: "Transform your operational data into actionable insights with real-time AI analysis"
  },
  {
    icon: ChartBarIcon,
    title: "Instant Analytics",
    description: "Monitor KPIs and business metrics directly in your browser"
  },
  {
    icon: BoltIcon,
    title: "Quick Actions",
    description: "Execute workflows and automations from any webpage"
  }
];

const screenshots = [
  {
    url: "/images/extension/dashboard.png",
    title: "Comprehensive Dashboard",
    description: "Full operational visibility in your browser"
  },
  {
    url: "/images/extension/insights.png",
    title: "AI Insights Panel",
    description: "Real-time business intelligence"
  },
  {
    url: "/images/extension/actions.png",
    title: "Quick Actions",
    description: "One-click workflow automation"
  },
  {
    url: "/images/extension/settings.png",
    title: "Easy Configuration",
    description: "Simple setup and customization"
  }
];

const setupSteps = [
    {
        title: "Install Extension",
        description: "Add OpexAI to your browser with a single click",
        icon: CloudArrowUpIcon,
        content: (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="flex items-center gap-4 mt-0" // Changed from text-center to flex and removed vertical margin
          >
            <a
              href="https://chrome.google.com/webstore/detail/[your-extension-id]"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-4 hover:opacity-90 transition-opacity"
            >
              <img
                src="https://fonts.gstatic.com/s/i/productlogos/chrome_store/v8/192px.svg"
                alt="Available in the Chrome Web Store"
                className="h-16"
              />
              <span className="text-white font-light text-lg">
                Add to Chrome
              </span>
            </a>
          </motion.div>
        )
      },
    {
      title: "Sign In",
      description: "Connect with your OpexAI account",
      icon: CogIcon,
      image: "/images/extension/signin-step.png"
    },
    {
      title: "Get Started",
      description: "Access AI-powered insights across the web",
      icon: ArrowRightIcon,
      image: "/images/extension/usage-step.png"
    }
  ];
  

export default function ExtensionPage() {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Helmet>
        <title>OpexAI Extension - AI-Powered Business Intelligence for Your Browser</title>
        <meta name="description" content="Transform your browser into an AI-powered command center. Get real-time business insights, analytics, and automation capabilities with OpexAI's browser extension." />
        <link rel="chrome-webstore-item" href="https://chrome.google.com/webstore/detail/[your-extension-id]" />
      </Helmet>

      <PageHero 
        subtitle="Browser Extension"
        title="AI-Powered Business Intelligence"
        description="Transform your browser into an intelligent operations hub. Get real-time insights and automate workflows directly where you work."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Key Features */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative group"
            >
              <div className="relative bg-gray-800/40 backdrop-blur-xl rounded-xl p-6 border border-gray-700/50">
                <feature.icon className="h-8 w-8 text-primary-500 mb-4" />
                <h3 className="text-xl font-light text-white mb-2">{feature.title}</h3>
                <p className="text-gray-400 font-light">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Screenshots */}
        <div className="mb-16">
          <h2 className="text-3xl font-light text-white mb-8 text-center">Extension Preview</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {screenshots.map((screenshot, index) => (
              <motion.div
                key={screenshot.title}
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="relative"
              >
                <div className="aspect-[1280/800] rounded-xl overflow-hidden">
                  <img 
                    src={screenshot.url} 
                    alt={screenshot.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-light text-white">{screenshot.title}</h3>
                  <p className="text-gray-400 font-light">{screenshot.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Getting Started Guide */}
        <section className="py-24">
  <h2 className="text-3xl font-light text-white mb-12 text-center">Quick Start Guide</h2>
  <div className="flex gap-12">
    {/* Left side: Steps */}
    <div className="flex-1 space-y-12">
      {setupSteps.map((step, index) => (
        <motion.div
          key={step.title}
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.1 }}
          className="space-y-4"
        >
          <div className="flex items-center gap-4">
            <step.icon className="h-8 w-8 text-primary-500" />
            <h3 className="text-2xl font-light text-white">{step.title}</h3>
          </div>
          <p className="text-gray-400 font-light text-lg pl-12">{step.description}</p>
          {index === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="flex items-center gap-4 pl-12"
            >
              <a
                href="https://chrome.google.com/webstore/detail/[your-extension-id]"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-4 hover:opacity-90 transition-opacity"
              >
                <img
                  src="https://fonts.gstatic.com/s/i/productlogos/chrome_store/v8/192px.svg"
                  alt="Available in the Chrome Web Store"
                  className="h-16"
                />
                <span className="text-white font-light text-lg">
                  Add to Chrome
                </span>
              </a>
            </motion.div>
          )}
        </motion.div>
      ))}
    </div>

    {/* Right side: Video */}
    <motion.div 
  className="flex-1 flex items-center justify-center"
  initial={{ opacity: 0, x: 20 }}
  whileInView={{ opacity: 1, x: 0 }}
  viewport={{ once: true }}
>
  <ClosedBetaForm />
</motion.div>
  </div>
</section>


      </div>
    </div>
  );
}