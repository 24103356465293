import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import ClosedBetaForm from '../components/ClosedBetaForm';

export default function Knowledge() {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Helmet>
        <title>Knowledge Hub | OpexAI - AI-Driven Analytics & Automation</title>
        <meta name="description" content="Access OpexAI's knowledge resources and join our closed beta program to explore AI-powered operational intelligence." />
      </Helmet>

      <PageHero 
        subtitle="Knowledge Hub"
        title="Learn & Grow with"
        highlightedText="OpexAI"
        description="Discover how AI is transforming business operations and stay updated with our latest developments."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-12 items-start">
          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-8"
          >
            <div className="prose prose-invert">
              <h2 className="text-3xl font-light text-white">Coming Soon</h2>
              <div className="bg-gradient-to-r from-primary-500/10 to-secondary-500/10 rounded-xl p-6 backdrop-blur-sm">
              <h3 className="text-xl font-light text-white mb-3">Alpha Testing Phase</h3>
              <p className="text-gray-400 font-light">
                We're currently fine-tuning our platform with selected alpha users. Your early participation and feedback will be invaluable in creating the most effective operational intelligence solution.
              </p>
            </div>
              <p className="text-gray-300 font-light text-lg">
                Soon, you'll have access to:
              </p>
              <ul className="text-gray-300 font-light space-y-4">
                <li>Comprehensive guides and tutorials</li>
                <li>Best practices for AI-driven operations</li>
                <li>Integration documentation</li>
                <li>Case studies and success stories</li>
                <li>API documentation</li>
                <li>Video tutorials and webinars</li>
              </ul>
              <p className="text-gray-300 font-light text-lg mt-6">
                Join our closed beta program to be among the first to access these resources and help shape the future of operational intelligence.
              </p>
            </div>


          </motion.div>

          {/* Beta Signup Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="sticky top-24"
          >
            <ClosedBetaForm />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
