

export default function GoogleAppointment({ className = '' }) {
  return (
    <div className={`w-full h-[700px] rounded-lg overflow-hidden dark:bg-gray-800 ${className}`}>
  <iframe
    src='https://calendar.google.com/calendar/appointments/schedules/AcZssZ2bktWc73v0aK8tqPIvnKe88LWx4g5ZjmhZHMhyHYcG1RF98tT80hFWS5obDAjBKc3LM9ujRl2U?gv=true&showHeader=0'
    style={{ 
      width: '100%',
      header: 'display:none!important',
      height: '100%', 
      border: 0,
      filter: 'invert(1) hue-rotate(180deg)' // This will be applied in dark mode
    }}
    className="dark:opacity-90" // Additional dark mode adjustments
    frameBorder="0"
    title="OpexAI Meeting Scheduler"
  />
    </div>
  );
}
