import { useState } from 'react';
import { motion } from 'framer-motion';
import { Card } from './ui/Card';
import { Badge } from './ui/Badge';
import {
  AreaChart, Area, BarChart, Bar, LineChart, Line,
  PieChart, Pie, ComposedChart, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import {
  CurrencyDollarIcon, ChartBarIcon, FaceSmileIcon, 
  ArrowTrendingDownIcon
} from '@heroicons/react/24/outline';

const timeFrames = [
  { label: 'Last 30 Days', value: 'past-30' },
  { label: 'Last Quarter', value: 'past-quarter' },
  { label: 'Next 30 Days', value: 'future-30' },
  { label: 'Next Quarter', value: 'future-quarter' }
];

const generateData = (timeFrame) => {
  const baseData = {
    'past-30': [
      { month: 'Jun 1', efficiency: 65, cost: 1200, automation: 45, satisfaction: 82, leads: 120, marketing: 85 },
      { month: 'Jun 15', efficiency: 72, cost: 1100, automation: 52, satisfaction: 85, leads: 150, marketing: 88 },
      { month: 'Jun 30', efficiency: 78, cost: 950, automation: 58, satisfaction: 88, leads: 180, marketing: 92 }
    ],
    'past-quarter': [
      { month: 'Apr', efficiency: 60, cost: 1300, automation: 40, satisfaction: 80, leads: 100, marketing: 82 },
      { month: 'May', efficiency: 68, cost: 1150, automation: 48, satisfaction: 84, leads: 130, marketing: 86 },
      { month: 'Jun', efficiency: 78, cost: 950, automation: 58, satisfaction: 88, leads: 180, marketing: 92 }
    ],
    'future-30': [
      { month: 'Jul 1', efficiency: 82, cost: 900, automation: 62, satisfaction: 90, leads: 200, marketing: 94, predicted: true },
      { month: 'Jul 15', efficiency: 85, cost: 850, automation: 65, satisfaction: 92, leads: 220, marketing: 96, predicted: true },
      { month: 'Jul 30', efficiency: 88, cost: 800, automation: 68, satisfaction: 94, leads: 240, marketing: 98, predicted: true }
    ],
    'future-quarter': [
      { month: 'Jul', efficiency: 85, cost: 850, automation: 65, satisfaction: 92, leads: 220, marketing: 95, predicted: true },
      { month: 'Aug', efficiency: 88, cost: 800, automation: 70, satisfaction: 94, leads: 260, marketing: 97, predicted: true },
      { month: 'Sep', efficiency: 92, cost: 750, automation: 75, satisfaction: 96, leads: 300, marketing: 99, predicted: true }
    ]
  };
  return baseData[timeFrame];
};

const calculateKPIs = (data) => [
  {
    title: 'Sales Performance',
    value: `${data[data.length-1].efficiency}%`,
    trend: `${(data[data.length-1].efficiency - data[0].efficiency).toFixed(1)}%`,
    icon: CurrencyDollarIcon,
    color: 'blue'
  },
  {
    title: 'Lead Generation',
    value: data[data.length-1].leads,
    trend: `${((data[data.length-1].leads - data[0].leads) / data[0].leads * 100).toFixed(1)}%`,
    icon: ChartBarIcon,
    color: 'green'
  },
  {
    title: 'Marketing Score',
    value: `${data[data.length-1].marketing}%`,
    trend: `${(data[data.length-1].marketing - data[0].marketing).toFixed(1)}%`,
    icon: FaceSmileIcon,
    color: 'amber'
  },
  {
    title: 'Cost Reduction',
    value: `${data[data.length-1].cost}`,
    trend: `${((data[0].cost - data[data.length-1].cost) / data[0].cost * 100).toFixed(1)}%`,
    icon: ArrowTrendingDownIcon,
    color: 'violet'
  }
];

export default function DashboardPreview() {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('past-30');
  const [data, setData] = useState(generateData('past-30'));
  const isPredictive = selectedTimeFrame.includes('future');

  const handleTimeFrameChange = (newTimeFrame) => {
    setSelectedTimeFrame(newTimeFrame);
    setData(generateData(newTimeFrame));
  };

  const kpiCards = calculateKPIs(data);

  return (
    <section className="relative -mt-40 z-10 hidden sm:block">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8"
        >
          <div className="flex justify-between items-center mb-8">
            <div>
              <h2 className="text-2xl font-light text-gray-900 dark:text-white">
                Company Overview
              </h2>
              <p className="text-gray-600 dark:text-gray-400 mt-1">
                Real-time insights across your organization
              </p>
            </div>
            
            <select 
              className="bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg px-4 py-2 text-gray-700 dark:text-gray-200"
              value={selectedTimeFrame}
              onChange={(e) => handleTimeFrameChange(e.target.value)}
            >
              {timeFrames.map(frame => (
                <option key={frame.value} value={frame.value}>{frame.label}</option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
  {kpiCards.map((card, index) => (
    <motion.div
      key={card.title}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
    >
      <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
        <div className="flex items-center justify-between mb-2">
          <card.icon className="h-6 w-6 text-primary-500" />
          <Badge variant={parseFloat(card.trend) > 0 ? 'success' : 'error'} className="text-xs">
            {card.trend}
          </Badge>
        </div>
        <h3 className="text-xs text-gray-500 dark:text-gray-400 font-light">
          {card.title}
        </h3>
        <p className="text-lg font-light text-gray-900 dark:text-white mt-1">
          {card.value}
        </p>
      </Card>
    </motion.div>
  ))}
</div>
 {/* Sales Performance - Composed Chart */}
<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
  <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
    <h3 className="text-sm font-light mb-3 text-gray-700 dark:text-gray-300">Sales Performance</h3>
    <ResponsiveContainer width="100%" height={180}>
      <AreaChart data={data} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
        <defs>
          <linearGradient id="colorEfficiency" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#6366F1" stopOpacity={0.3}/>
            <stop offset="95%" stopColor="#6366F1" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" vertical={false} opacity={0.2} />
        <XAxis 
          dataKey="month" 
          fontSize={12} 
          tickMargin={10}
          axisLine={false}
          tickLine={false}
          stroke="#9CA3AF"
        />
        <YAxis 
          fontSize={12} 
          tickMargin={10}
          axisLine={false}
          tickLine={false}
          stroke="#9CA3AF"
        />
        <Tooltip 
          contentStyle={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(8px)',
            borderRadius: '8px',
            border: '1px solid rgba(229, 231, 235, 0.5)',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
            fontSize: '12px',
            padding: '8px'
          }}
        />
        <Area 
          type="monotone" 
          dataKey="efficiency" 
          stroke="#6366F1" 
          strokeWidth={2}
          strokeDasharray={isPredictive ? "5 5" : "0"}
          fill="url(#colorEfficiency)" 
        />
      </AreaChart>
    </ResponsiveContainer>
  </Card>

            {/* Lead Generation - Composed Chart */}
            <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
  <h3 className="text-sm font-light mb-3 text-gray-700 dark:text-gray-300">Lead Generation</h3>
  <ResponsiveContainer width="100%" height={180}>
    <ComposedChart data={data} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" vertical={false} opacity={0.2} />
      <XAxis 
        dataKey="month" 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <YAxis 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <Tooltip 
        contentStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          borderRadius: '8px',
          border: '1px solid rgba(229, 231, 235, 0.5)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          fontSize: '12px',
          padding: '8px'
        }}
      />
      <Bar 
        dataKey="leads" 
        fill="#14B8A6" 
        radius={[4, 4, 0, 0]} 
        fillOpacity={isPredictive ? 0.7 : 1}
      />
      <Line 
        type="monotone" 
        dataKey="marketing" 
        stroke="#F59E0B" 
        strokeWidth={2}
        strokeDasharray={isPredictive ? "5 5" : "0"}
      />
    </ComposedChart>
  </ResponsiveContainer>
</Card>
            {/* Marketing Performance - Enhanced Composed Chart */}
            <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
  <h3 className="text-sm font-light mb-3 text-gray-700 dark:text-gray-300">Marketing Performance</h3>
  <ResponsiveContainer width="100%" height={180}>
    <ComposedChart data={data} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
      <defs>
        <linearGradient id="colorMarketing" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#F59E0B" stopOpacity={0.3}/>
          <stop offset="95%" stopColor="#F59E0B" stopOpacity={0}/>
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" vertical={false} opacity={0.2} />
      <XAxis 
        dataKey="month" 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <YAxis 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <Tooltip 
        contentStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          borderRadius: '8px',
          border: '1px solid rgba(229, 231, 235, 0.5)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          fontSize: '12px',
          padding: '8px'
        }}
      />
      <Bar dataKey="marketing" fill="url(#colorMarketing)" radius={[4, 4, 0, 0]} />
      <Line 
        type="monotone" 
        dataKey="leads" 
        stroke="#F59E0B" 
        strokeWidth={2}
        dot={{ fill: '#F59E0B', strokeWidth: 2 }}
      />
    </ComposedChart>
  </ResponsiveContainer>
</Card>
            {/* Cost Analysis - Bar Chart */}
            <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
  <h3 className="text-sm font-light mb-3 text-gray-700 dark:text-gray-300">Cost Analysis</h3>
  <ResponsiveContainer width="100%" height={180}>
    <BarChart data={data} layout="vertical" margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" horizontal={false} opacity={0.2} />
      <XAxis 
        type="number" 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <YAxis 
        dataKey="month" 
        type="category" 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <Tooltip 
        contentStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          borderRadius: '8px',
          border: '1px solid rgba(229, 231, 235, 0.5)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          fontSize: '12px',
          padding: '8px'
        }}
      />
      <Bar dataKey="cost" fill="#EC4899" radius={[0, 4, 4, 0]} />
    </BarChart>
  </ResponsiveContainer>
</Card>

            {/* Automation Level - Pie Chart */}
            <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
  <h3 className="text-sm font-light mb-3 text-gray-700 dark:text-gray-300">Automation Level</h3>
  <ResponsiveContainer width="100%" height={180}>
    <PieChart margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
      <Pie
        data={[
          { name: 'Automated', value: data[data.length-1].automation },
          { name: 'Manual', value: 100 - data[data.length-1].automation }
        ]}
        innerRadius={50}
        outerRadius={70}
        paddingAngle={5}
        dataKey="value"
      >
        <Cell fill="#8B5CF6" />
        <Cell fill="#C4B5FD" />
      </Pie>
      <Tooltip 
        contentStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          borderRadius: '8px',
          border: '1px solid rgba(229, 231, 235, 0.5)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          fontSize: '12px',
          padding: '8px'
        }}
      />
    </PieChart>
  </ResponsiveContainer>
</Card>
            {/* Satisfaction Trends - Line Chart with Multiple Lines */}
            <Card className="p-4 shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 border border-gray-100/50 dark:border-gray-700/50">
  <h3 className="text-sm font-light mb-3 text-gray-700 dark:text-gray-300">Satisfaction Trends</h3>
  <ResponsiveContainer width="100%" height={180}>
    <LineChart data={data} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" vertical={false} opacity={0.2} />
      <XAxis 
        dataKey="month" 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <YAxis 
        fontSize={12} 
        tickMargin={10}
        axisLine={false}
        tickLine={false}
        stroke="#9CA3AF"
      />
      <Tooltip 
        contentStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(8px)',
          borderRadius: '8px',
          border: '1px solid rgba(229, 231, 235, 0.5)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          fontSize: '12px',
          padding: '8px'
        }}
      />
      <Line 
        type="monotone" 
        dataKey="satisfaction" 
        stroke="#10B981" 
        strokeWidth={2} 
        dot={false}
      />
      <Line 
        type="monotone" 
        dataKey="efficiency" 
        stroke="#6366F1" 
        strokeWidth={2} 
        dot={false}
      />
    </LineChart>
  </ResponsiveContainer>
</Card>
{isPredictive && (
            <div className="mt-4">
              <Badge variant="ai" className="text-xs font-light">
                Dashed lines and lighter colors indicate AI-predicted values
              </Badge>
            </div>
          )}
          </div>
        </motion.div>
      </div>
    </section>
  );
}