import { motion } from 'framer-motion';
import { useState } from 'react';

const plans = [
  {
    name: 'Starter',
    price: '49',
    features: [
      'Up to 5 users',
      'Basic analytics',
      'Data integration',
      'Email support'
    ],
    highlighted: false
  },
  {
    name: 'Professional',
    price: '99',
    features: [
      'Up to 10 users',
      'Advanced analytics',
      'Custom integrations',
      'Priority support',
      'API access'
    ],
    highlighted: true
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    features: [
      'Unlimited users',
      'Custom solutions',
      'Dedicated support',
      'SLA guarantee',
      'Custom training'
    ],
    highlighted: false
  }
];

export default function Pricing() {
  const [billingCycle, setBillingCycle] = useState('monthly');

  return (
    <section id="pricing" className="py-24 bg-gradient-to-r from-primary-500/10 to-secondary-500/10 dark:from-primary-500/20 dark:to-secondary-500/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-display font-extralight text-gray-900 dark:text-white mb-4">
            Simple, Transparent Pricing
          </h2>
          <div className="flex justify-center items-center space-x-4">
            <span className={`text-lg ${billingCycle === 'monthly' ? 'text-primary-500' : 'text-gray-500'}`}>Monthly</span>
            <button
              onClick={() => setBillingCycle(prev => prev === 'monthly' ? 'annual' : 'monthly')}
              className="relative w-14 h-8 bg-primary-200 rounded-full p-1 transition-colors duration-300"
            >
              <motion.div
                animate={{ x: billingCycle === 'monthly' ? 0 : 24 }}
                className="w-6 h-6 bg-primary-500 rounded-full"
              />
            </button>
            <span className={`text-lg ${billingCycle === 'annual' ? 'text-primary-500' : 'text-gray-500'}`}>Annual</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <motion.div
              key={plan.name}
              whileHover={{ y: -8 }}
              className={`relative p-8 bg-white dark:bg-gray-800 rounded-2xl shadow-xl ${
                plan.highlighted ? 'border-2 border-primary-500' : ''
              }`}
            >
              {plan.highlighted && (
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <span className="bg-primary-500 text-white px-4 py-1 rounded-full text-sm">
                    Most Popular
                  </span>
                </div>
              )}
              <h3 className="text-xl font-light text-gray-900 dark:text-white mb-4">{plan.name}</h3>
              <div className="mb-8">
                <span className="text-4xl font-bold text-gray-900 dark:text-white">${plan.price}</span>
                {plan.price !== 'Custom' && <span className="text-gray-500">/month</span>}
              </div>
              <ul className="space-y-4 mb-8">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                    <svg className="w-5 h-5 text-primary-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`w-full py-3 rounded-lg ${
                  plan.highlighted
                    ? 'bg-primary-500 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                }`}
              >
                Get Started
              </motion.button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
