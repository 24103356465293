import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import DemoAppointment from '../components/ui/DemoAppointment';
import { 
  ChartBarIcon, 
  BoltIcon, 
  SparklesIcon,
  RocketLaunchIcon,
  CubeTransparentIcon
} from '@heroicons/react/24/outline';

const demoFeatures = [
  {
    icon: ChartBarIcon,
    title: "Real-time Analytics",
    description: "See how OpexAI provides instant insights across your operations"
  },
  {
    icon: BoltIcon,
    title: "Workflow Automation",
    description: "Experience intelligent automation that adapts to your business"
  },
  {
    icon: SparklesIcon,
    title: "AI Capabilities",
    description: "Discover our advanced AI features in action"
  }
];

const demoHighlights = [
  {
    icon: RocketLaunchIcon,
    title: "Personalized Demo",
    points: [
      "Custom scenarios based on your industry",
      "Interactive walkthrough of key features",
      "Live Q&A with product specialists",
      "Integration possibilities discussion"
    ]
  },
  {
    icon: CubeTransparentIcon,
    title: "Technical Deep Dive",
    points: [
      "Architecture overview",
      "Security measures",
      "API capabilities",
      "Deployment options"
    ]
  }
];

export default function Demo() {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Helmet>
        <title>Book a Demo | OpexAI - AI-Driven Analytics & Automation</title>
        <meta name="description" content="Experience the power of AI-driven operational intelligence. Book a personalized demo of OpexAI's revolutionary platform." />
      </Helmet>

      <PageHero 
        subtitle="Product Demo"
        title="Experience the Future of"
        highlightedText="Operations"
        description="See how OpexAI transforms business operations with AI-powered intelligence."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-12 items-start">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-12"
          >


            {demoHighlights.map((section, index) => (
              <motion.div
                key={section.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gradient-to-r from-primary-500/10 to-secondary-500/10 rounded-xl p-6 backdrop-blur-sm"
              >
                <div className="flex items-center gap-3 mb-4">
                  <section.icon className="h-6 w-6 text-primary-500" />
                  <h3 className="text-xl font-light text-white">{section.title}</h3>
                </div>
                <ul className="space-y-2">
                  {section.points.map((point, idx) => (
                    <li key={idx} className="flex items-center gap-2 text-gray-300 font-light">
                      <span className="h-1.5 w-1.5 rounded-full bg-primary-500"></span>
                      {point}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
                        <div className="grid grid-cols-1 gap-8">
              {demoFeatures.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/40 backdrop-blur-xl rounded-xl p-6 border border-gray-700/50"
                >
                  <div className="flex items-center gap-4">
                    <feature.icon className="h-8 w-8 text-primary-500" />
                    <div>
                      <h3 className="text-xl font-light text-white">{feature.title}</h3>
                      <p className="text-gray-400 font-light">{feature.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="sticky top-24"
          >
            <DemoAppointment />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
