export const CONFIG = {
    hubspot: {
      portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
      contactFormId: process.env.REACT_APP_HUBSPOT_CONTACT_FORM_ID,
      demoFormId: process.env.REACT_APP_HUBSPOT_DEMO_FORM_ID,
      newsletterFormId: process.env.REACT_APP_HUBSPOT_NEWSLETTER_FORM_ID,
      featureFormId: process.env.REACT_APP_HUBSPOT_FEATURE_FORM_ID,
      closedBetaFormId: process.env.REACT_APP_HUBSPOT_CLOSED_BETA_FORM_ID,
    },
    calendar: {
      appointmentUrl: process.env.REACT_APP_GOOGLE_CALENDAR_URL,
      demoAppointmentUrl: process.env.REACT_APP_GOOGLE_CALENDAR_DEMO_URL,
    }
  };
  