import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import CTA from '../components/CTA';
import { 
    BuildingOfficeIcon,
    ChartBarIcon,
    CurrencyDollarIcon,
    MegaphoneIcon,
    ArrowsRightLeftIcon,
    ShoppingBagIcon,
    CogIcon,
    WrenchScrewdriverIcon,
    TruckIcon
  } from '@heroicons/react/24/outline';
  
  const departments = [
    {
      id: 'cross-department',
      icon: ArrowsRightLeftIcon,
      title: 'Cross Department',
      description: 'Unified solutions that enhance collaboration and efficiency across all departments',
      roles: [
        {
          title: 'Project Coordinator',
          useCases: [
            'Cross-functional team coordination',
            'Project timeline synchronization',
            'Resource sharing optimization',
            'Inter-department communication',
            'Progress tracking across teams'
          ]
        },
        {
          title: 'Business Analyst',
          useCases: [
            'Process optimization across departments',
            'Cross-functional data analysis',
            'Efficiency improvement recommendations',
            'Strategic initiative tracking',
            'Department collaboration metrics'
          ]
        },
        {
          title: 'Quality Manager',
          useCases: [
            'Quality standards monitoring',
            'Cross-department compliance tracking',
            'Process improvement coordination',
            'Risk assessment across functions',
            'Performance metrics standardization'
          ]
        }
      ]
    },
    {
      id: 'revops',
      icon: ChartBarIcon,
      title: 'Revenue Operations',
      description: 'Align sales, marketing, and customer success for revenue growth',
      roles: [
        {
          title: 'RevOps Director',
          useCases: [
            'Revenue pipeline visualization',
            'Cross-functional performance metrics',
            'Sales and marketing alignment tracking',
            'Customer lifecycle optimization',
            'Revenue forecasting and planning'
          ]
        },
        {
          title: 'Revenue Analytics Manager',
          useCases: [
            'Revenue attribution modeling',
            'Conversion rate optimization',
            'Customer journey analytics',
            'Pricing strategy optimization',
            'Growth opportunity identification'
          ]
        },
        {
          title: 'Sales Operations Manager',
          useCases: [
            'Sales process automation',
            'Territory optimization',
            'Quote-to-cash efficiency',
            'Sales enablement tracking',
            'Performance metrics analysis'
          ]
        },
        {
          title: 'Marketing Operations Specialist',
          useCases: [
            'Campaign attribution tracking',
            'Lead scoring optimization',
            'Marketing automation efficiency',
            'Tech stack performance analysis',
            'Data quality management'
          ]
        }
      ]
    },
    {
      id: 'executives',
      icon: BuildingOfficeIcon,
      title: 'Executives & Leadership',
      description: 'Strategic insights and decision-making tools for company leadership',
      roles: [
        {
          title: 'CEO',
          useCases: [
            'Company-wide performance dashboard',
            'Strategic decision support',
            'Risk assessment and mitigation',
            'Resource allocation optimization',
            'Growth opportunity identification'
          ]
        },
        {
          title: 'COO',
          useCases: [
            'Operational efficiency metrics',
            'Process optimization insights',
            'Cross-department coordination',
            'Resource utilization tracking',
            'Performance bottleneck identification'
          ]
        },
        {
          title: 'CTO',
          useCases: [
            'Technology stack optimization',
            'Innovation pipeline management',
            'Technical resource allocation',
            'Digital transformation tracking',
            'Tech investment ROI analysis'
          ]
        }
      ]
    },
    {
      id: 'operations',
      icon: ChartBarIcon,
      title: 'Operations',
      description: 'Streamline processes and optimize workflow efficiency',
      roles: [
        {
          title: 'Operations Manager',
          useCases: [
            'Process automation workflows',
            'Team productivity analytics',
            'Resource allocation optimization',
            'Cost reduction opportunities',
            'Performance tracking'
          ]
        },
        {
          title: 'Project Manager',
          useCases: [
            'Project timeline optimization',
            'Resource management',
            'Risk identification',
            'Progress tracking',
            'Team coordination'
          ]
        },
        {
          title: 'Supply Chain Manager',
          useCases: [
            'Inventory optimization',
            'Supplier performance tracking',
            'Logistics efficiency analysis',
            'Supply chain risk management',
            'Cost optimization insights'
          ]
        }
      ]
    },
    {
      id: 'finance',
      icon: CurrencyDollarIcon,
      title: 'Finance',
      description: 'AI-powered financial insights and automated reporting',
      roles: [
        {
          title: 'CFO',
          useCases: [
            'Financial performance dashboard',
            'Cash flow optimization',
            'Investment opportunity analysis',
            'Risk management insights',
            'Strategic financial planning'
          ]
        },
        {
          title: 'Financial Analyst',
          useCases: [
            'Automated financial reporting',
            'Trend analysis and forecasting',
            'Budget variance tracking',
            'Cost optimization insights',
            'Revenue prediction models'
          ]
        },
        {
          title: 'Controller',
          useCases: [
            'Financial controls monitoring',
            'Compliance tracking',
            'Audit preparation automation',
            'Financial policy enforcement',
            'Risk assessment automation'
          ]
        }
      ]
    },
    {
      id: 'sales',
      icon: MegaphoneIcon,
      title: 'Sales & Marketing',
      description: 'Drive growth with AI-enhanced sales and marketing tools',
      roles: [
        {
          title: 'Sales Director',
          useCases: [
            'Pipeline optimization',
            'Team performance analytics',
            'Revenue forecasting',
            'Territory planning',
            'Customer acquisition strategy'
          ]
        },
        {
          title: 'Marketing Manager',
          useCases: [
            'Campaign performance tracking',
            'ROI optimization',
            'Content effectiveness analysis',
            'Audience targeting insights',
            'Marketing budget allocation'
          ]
        },
        {
          title: 'Digital Marketing Specialist',
          useCases: [
            'Digital campaign analytics',
            'Social media performance tracking',
            'SEO optimization insights',
            'Content engagement metrics',
            'Conversion rate optimization'
          ]
        }
      ]
    },
    {
      id: 'procurement',
      icon: ShoppingBagIcon,
      title: 'Procurement',
      description: 'Optimize purchasing and vendor management processes',
      roles: [
        {
          title: 'Procurement Manager',
          useCases: [
            'Vendor performance analytics',
            'Cost savings identification',
            'Purchase order optimization',
            'Supplier risk assessment',
            'Contract compliance monitoring'
          ]
        },
        {
          title: 'Purchasing Specialist',
          useCases: [
            'Order tracking automation',
            'Inventory level optimization',
            'Price comparison analytics',
            'Supplier evaluation metrics',
            'Purchase pattern analysis'
          ]
        }
      ]
    },
    {
      id: 'it',
      icon: CogIcon,
      title: 'IT Department',
      description: 'Enhance technical operations and system performance',
      roles: [
        {
          title: 'IT Manager',
          useCases: [
            'System performance monitoring',
            'Security compliance tracking',
            'Resource utilization analytics',
            'Project prioritization',
            'Technology stack optimization'
          ]
        },
        {
          title: 'System Administrator',
          useCases: [
            'Infrastructure monitoring',
            'Automated troubleshooting',
            'Capacity planning',
            'Security incident tracking',
            'Performance optimization'
          ]
        }
      ]
    },
    {
      id: 'maintenance',
      icon: WrenchScrewdriverIcon,
      title: 'Maintenance',
      description: 'Streamline maintenance operations and asset management',
      roles: [
        {
          title: 'Maintenance Manager',
          useCases: [
            'Preventive maintenance scheduling',
            'Asset performance tracking',
            'Resource allocation optimization',
            'Maintenance cost analysis',
            'Equipment lifecycle management'
          ]
        },
        {
          title: 'Facility Manager',
          useCases: [
            'Facility usage analytics',
            'Energy efficiency tracking',
            'Maintenance request optimization',
            'Space utilization analysis',
            'Vendor performance monitoring'
          ]
        }
      ]
    },
    {
      id: 'logistics',
      icon: TruckIcon,
      title: 'Logistics',
      description: 'Optimize transportation and warehouse operations',
      roles: [
        {
          title: 'Logistics Manager',
          useCases: [
            'Route optimization',
            'Warehouse efficiency tracking',
            'Delivery performance analytics',
            'Cost per mile analysis',
            'Fleet utilization insights'
          ]
        },
        {
          title: 'Warehouse Supervisor',
          useCases: [
            'Inventory turnover tracking',
            'Pick and pack optimization',
            'Storage space utilization',
            'Labor productivity analysis',
            'Equipment usage monitoring'
          ]
        }
      ]
    }
  ];
  

export default function Solutions() {
  const [selectedDept, setSelectedDept] = useState(departments[0]);
  const [selectedRole, setSelectedRole] = useState(departments[0].roles[0]);

  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Helmet>
        <title>Solutions | OpexAI - Department & Role-Based AI Solutions</title>
        <meta name="description" content="Discover how OpexAI's AI-driven solutions can transform operations across every department and role in your organization." />
      </Helmet>

      <PageHero 
        subtitle="Solutions"
        title="AI-Powered Solutions for"
        highlightedText="Every Role"
        description="Empower your teams with role-specific AI tools and insights for maximum operational efficiency."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid lg:grid-cols-12 gap-12">
          {/* Department Selection */}
          <div className="lg:col-span-3 space-y-4">
            {departments.map((dept) => (
              <motion.button
                key={dept.id}
                onClick={() => {
                  setSelectedDept(dept);
                  setSelectedRole(dept.roles[0]);
                }}
                className={`w-full p-4 rounded-xl backdrop-blur-xl border transition-all duration-300 ${
                  selectedDept.id === dept.id
                    ? 'bg-primary-500/20 border-primary-500'
                    : 'bg-gray-800/40 border-gray-700/50 hover:border-primary-500/50'
                }`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center gap-3">
                  <dept.icon className="h-6 w-6 text-primary-500" />
                  <span className="text-white font-light">{dept.title}</span>
                </div>
              </motion.button>
            ))}
          </div>

          {/* Role Selection and Use Cases */}
          <div className="lg:col-span-9 space-y-8">
            <motion.div
              key={selectedDept.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gray-800/40 backdrop-blur-xl rounded-xl p-8 border border-gray-700/50"
            >
              <h2 className="text-2xl font-light text-white mb-4">{selectedDept.title}</h2>
              <p className="text-gray-400 font-light mb-6">{selectedDept.description}</p>

              <div className="flex gap-4 mb-8">
                {selectedDept.roles.map((role) => (
                  <button
                    key={role.title}
                    onClick={() => setSelectedRole(role)}
                    className={`px-4 py-2 rounded-full transition-all duration-300 ${
                      selectedRole.title === role.title
                        ? 'bg-primary-500 text-white'
                        : 'bg-gray-700/50 text-gray-300 hover:bg-gray-700'
                    }`}
                  >
                    {role.title}
                  </button>
                ))}
              </div>

              <AnimatePresence mode="wait">
                <motion.div
                  key={selectedRole.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="space-y-4"
                >
                  {selectedRole.useCases.map((useCase, index) => (
                    <motion.div
                      key={useCase}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center gap-3"
                    >
                      <span className="h-1.5 w-1.5 rounded-full bg-primary-500"></span>
                      <span className="text-gray-300 font-light">{useCase}</span>
                    </motion.div>
                  ))}
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </div>
        </div>
      </div>
      <CTA />
    </div>
  );
}
