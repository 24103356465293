import { CONFIG } from '../../config/env';

export default function DemoAppointment({ className = '' }) {
  return (
    <div className={`w-full h-[700px] rounded-lg overflow-hidden dark:bg-gray-800 ${className}`}>
      <iframe
        src={CONFIG.calendar.demoAppointmentUrl}
        style={{ 
          width: '100%',
          height: '100%', 
          border: 0,
          filter: 'invert(1) hue-rotate(180deg)'
        }}
        className="dark:opacity-90"
        frameBorder="0"
        title="OpexAI Demo Scheduler"
      />
    </div>
  );
}
