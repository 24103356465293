export const Card = ({
  title,
  children,
  className = ""
}) => {
  return (
    <div className={`
      bg-white/80 dark:bg-gray-800/80
      backdrop-blur-xl
      rounded-2xl
      shadow-[0_8px_30px_rgb(0,0,0,0.04)]
      dark:shadow-[0_8px_30px_rgb(0,0,0,0.1)]
      border border-gray-100/50 dark:border-gray-700/50
      hover:shadow-[0_20px_40px_rgb(0,0,0,0.08)]
      dark:hover:shadow-[0_20px_40px_rgb(0,0,0,0.2)]
      transition-all duration-300
      ${className}
    `}>
      {title && (
        <h3 className="text-xl font-extralight text-gray-900 dark:text-white mb-4">
          {title}
        </h3>
      )}
      {children}
    </div>
  )
}  