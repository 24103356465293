import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function MainLayout({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Navigation />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
