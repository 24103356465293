import { motion } from 'framer-motion';
import { Tab } from '@headlessui/react';
import { Link } from 'react-router-dom';
import {
  ChartBarIcon,
  CogIcon,
  DocumentChartBarIcon,
  UserGroupIcon,
  SparklesIcon,
  ArrowTrendingUpIcon,
  GlobeAltIcon,
  ChatBubbleBottomCenterTextIcon,
  PresentationChartLineIcon,
  CommandLineIcon
} from '@heroicons/react/24/outline';

const platformFeatures = [
  {
    title: "360° Insights",
    description: "Comprehensive data view across clients, projects, and business health",
    icon: ChartBarIcon
  },
  {
    title: "Smart Automation",
    description: "Custom automations across departments reducing manual work",
    icon: CogIcon
  },
  {
    title: "Custom Dashboards",
    description: "Role-specific metrics and KPIs for clear performance tracking",
    icon: DocumentChartBarIcon
  },
  {
    title: "Seamless Collaboration",
    description: "Cross-functional tools integrated with Slack and Teams",
    icon: UserGroupIcon
  },
  {
    title: "AI Recommendations",
    description: "Proactive suggestions for operational improvements",
    icon: SparklesIcon
  },
  {
    title: "Predictive Insights",
    description: "Data-backed recommendations for strategic planning",
    icon: ArrowTrendingUpIcon
  }
];

const extensionFeatures = [
  {
    title: "Instant Client Insights",
    description: "Real-time client summaries from multiple data sources",
    icon: GlobeAltIcon
  },
  {
    title: "Smart Suggestions",
    description: "Proactive recommendations for client interactions",
    icon: ChatBubbleBottomCenterTextIcon
  },
  {
    title: "Visual Analytics",
    description: "On-the-go access to client KPIs and trends",
    icon: PresentationChartLineIcon
  },
  {
    title: "AI Copilot",
    description: "Natural language queries for instant information access",
    icon: CommandLineIcon
  }
];

export default function Product() {
  return (
    <section id="product" className="py-24 bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-extralight text-white mb-4"
          >
            Our Product Suite
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-300"
          >
            Comprehensive tools for modern business operations
          </motion.p>
        </div>

        <Tab.Group>
          <Tab.List className="flex space-x-4 rounded-xl bg-gray-800/50 p-1 mb-12">
            <Tab className={({ selected }) =>
              `w-full rounded-lg py-3 text-sm font-medium leading-5 
              ${selected 
                ? 'bg-primary-500 text-white shadow'
                : 'text-gray-400 hover:text-white hover:bg-gray-800'
              }`
            }>
              Platform
            </Tab>
            <Tab className={({ selected }) =>
              `w-full rounded-lg py-3 text-sm font-medium leading-5
              ${selected 
                ? 'bg-primary-500 text-white shadow'
                : 'text-gray-400 hover:text-white hover:bg-gray-800'
              }`
            }>
              Browser Extension
            </Tab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {platformFeatures.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-6 border border-gray-700/50 hover:border-primary-500/50 transition-all duration-300"
                  >
                    <feature.icon className="h-8 w-8 text-primary-500 mb-4" />
                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </motion.div>
                ))}
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {extensionFeatures.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-800/50 backdrop-blur-xl rounded-2xl p-6 border border-gray-700/50 hover:border-primary-500/50 transition-all duration-300"
                  >
                    <feature.icon className="h-8 w-8 text-primary-500 mb-4" />
                    <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </motion.div>
                ))}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-16 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Link
              to="/product"
              className="inline-flex items-center px-8 py-3 rounded-full bg-primary-500 text-white font-medium shadow-lg shadow-primary-500/25 hover:shadow-primary-500/50 transition-all duration-300"
            >
              Learn More
              <svg
                className="ml-2 -mr-1 w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
