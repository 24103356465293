import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CONFIG } from '../../config/env';

const ConsentSection = ({ formData, handleChange }) => (
  <motion.div
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: 'auto' }}
    exit={{ opacity: 0, height: 0 }}
    className="mt-4 space-y-4 text-sm text-gray-600 dark:text-gray-400"
  >
    <p className="font-light">
      OpexAI is committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us.
    </p>
    
    <div className="space-y-3">
      <label className="flex items-start space-x-3">
        <input
          type="checkbox"
          name="communications_consent"
          checked={formData.communications_consent || false}
          onChange={handleChange}
          className="mt-1 h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
        />
        <span>I agree to receive other communications from OpexAI.</span>
      </label>

      <label className="flex items-start space-x-3">
        <input
          type="checkbox"
          name="privacy_consent"
          checked={formData.privacy_consent || false}
          onChange={handleChange}
          required
          className="mt-1 h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
        />
        <span>I agree to allow OpexAI to store and process my personal data.*</span>
      </label>
    </div>

    <p className="text-xs">
      You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our <a href="/privacy" className="text-primary-500 hover:text-primary-600">Privacy Policy</a>.
    </p>
  </motion.div>
);

export default function HubSpotForm({ formId, fields, onSuccess, onError, submitButtonText = 'Submit', variant = 'default' }) {
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState('idle');
  const [showConsent, setShowConsent] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Processing...');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('submitting');
    setLoadingMessage('Processing...');
    setErrorMessage('');

    try {
      const submissionData = {
        portalId: CONFIG.hubspot.portalId,
        formGuid: formId,
        fields: Object.entries(formData).map(([name, value]) => ({
          name,
          value: typeof value === 'boolean' ? value.toString() : value
        })),
        context: {
          pageUri: window.location.href,
          pageName: document.title
        }
      };

      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${CONFIG.hubspot.portalId}/${formId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify(submissionData)
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Form submission failed');
      }

      setLoadingMessage('Sent!');
      setTimeout(() => {
        setStatus('success');
        setFormData({});
        onSuccess?.();
      }, 1000);
    } catch (error) {
      setStatus('error');
      setErrorMessage(error.message);
      onError?.(error);
    }
  };

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData(prev => ({
      ...prev,
      [e.target.name]: value
    }));
  };

  const handleEmailFocus = () => {
    setShowConsent(true);
  };

// In the newsletter variant return statement:

if (variant === 'newsletter') {
  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-2 max-w-sm">
        <input
          type="email"
          name="email"
          value={formData.email || ''}
          onChange={handleChange}
          onFocus={handleEmailFocus}
          placeholder="Enter your email"
          className="h-10 px-4 rounded-lg bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500 w-full"
        />
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
          disabled={status === 'submitting' || !formData.privacy_consent}
          className="h-10 px-6 bg-gradient-to-r from-primary-500 to-secondary-500 text-white rounded-lg font-light hover:from-primary-600 hover:to-secondary-600 transition-all duration-200 disabled:opacity-50 w-full sm:w-auto"
        >
          {status === 'submitting' ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {loadingMessage}
            </span>
          ) : status === 'success' ? (
            <span className="flex items-center justify-center text-white">
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              Subscribed!
            </span>
          ) : submitButtonText}
        </motion.button>
      </form>

      {status === 'error' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-red-500 text-sm"
        >
          {errorMessage}
        </motion.div>
      )}

      <AnimatePresence>
        {showConsent && <ConsentSection formData={formData} handleChange={handleChange} />}
      </AnimatePresence>
    </div>
  );
}


  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {fields.map(({ name, label, type = 'text', required = false }) => (
        <div key={name}>
          <label className="block text-sm font-light text-gray-600 dark:text-gray-300 mb-2">
            {label}
          </label>
          {type === 'textarea' ? (
            <textarea
              name={name}
              value={formData[name] || ''}
              onChange={handleChange}
              required={required}
              rows={4}
              className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all duration-200 font-light text-gray-900 dark:text-gray-100"
            />
          ) : (
            <input
              type={type}
              name={name}
              value={formData[name] || ''}
              onChange={handleChange}
              required={required}
              className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all duration-200 font-light text-gray-900 dark:text-gray-100"
            />
          )}
        </div>
      ))}

      {status === 'error' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-red-500 text-sm"
        >
          {errorMessage}
        </motion.div>
      )}
      
      <button
        type="submit"
        disabled={status === 'submitting'}
        className="w-full px-6 py-3 bg-gradient-to-r from-primary-500 to-secondary-500 text-white rounded-xl font-light hover:from-primary-600 hover:to-secondary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-200 disabled:opacity-50"
      >
        {status === 'submitting' ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {loadingMessage}
          </span>
        ) : status === 'success' ? (
          <span className="flex items-center justify-center text-white">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            Message Sent!
          </span>
        ) : submitButtonText}
      </button>
    </form>
  );
}
