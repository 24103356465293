import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import ClosedBetaForm from '../components/ClosedBetaForm';
import { 
  BeakerIcon, 
  RocketLaunchIcon, 
  SparklesIcon,
  ShieldCheckIcon,
  CubeTransparentIcon 
} from '@heroicons/react/24/outline';

const alphaFeatures = [
  {
    icon: BeakerIcon,
    title: "Alpha Testing",
    description: "Be among the first to experience and shape our revolutionary AI platform"
  },
  {
    icon: RocketLaunchIcon,
    title: "Early Access",
    description: "Priority access to new features and capabilities as they're developed"
  },
  {
    icon: SparklesIcon,
    title: "Direct Impact",
    description: "Your feedback directly influences the platform's evolution"
  }
];

const betaHighlights = [
  {
    icon: ShieldCheckIcon,
    title: "Closed Beta Benefits",
    points: [
      "Exclusive early access to full platform features",
      "Direct communication channel with development team",
      "Priority support and implementation assistance",
      "Influence on product roadmap"
    ]
  },
  {
    icon: CubeTransparentIcon,
    title: "What to Expect",
    points: [
      "Regular feature updates and improvements",
      "Dedicated onboarding support",
      "Custom integration assistance",
      "Early adopter pricing benefits"
    ]
  }
];

export default function Trial() {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Helmet>
        <title>Join the Beta | OpexAI - AI-Driven Analytics & Automation</title>
        <meta name="description" content="Join OpexAI's closed beta program and be among the first to experience the future of AI-powered operational intelligence." />
      </Helmet>

      <PageHero 
        subtitle="Early Access"
        title="Shape the Future of"
        highlightedText="Operations"
        description="Join our exclusive beta program and help define the future of AI-powered business operations."
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-12 items-start">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-12"
          >
            <div className="grid grid-cols-1 gap-8">
              {alphaFeatures.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-800/40 backdrop-blur-xl rounded-xl p-6 border border-gray-700/50"
                >
                  <div className="flex items-center gap-4">
                    <feature.icon className="h-8 w-8 text-primary-500" />
                    <div>
                      <h3 className="text-xl font-light text-white">{feature.title}</h3>
                      <p className="text-gray-400 font-light">{feature.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            {betaHighlights.map((section, index) => (
              <motion.div
                key={section.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-gradient-to-r from-primary-500/10 to-secondary-500/10 rounded-xl p-6 backdrop-blur-sm"
              >
                <div className="flex items-center gap-3 mb-4">
                  <section.icon className="h-6 w-6 text-primary-500" />
                  <h3 className="text-xl font-light text-white">{section.title}</h3>
                </div>
                <ul className="space-y-2">
                  {section.points.map((point, idx) => (
                    <li key={idx} className="flex items-center gap-2 text-gray-300 font-light">
                      <span className="h-1.5 w-1.5 rounded-full bg-primary-500"></span>
                      {point}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="sticky top-24"
          >
            <ClosedBetaForm />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
