import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function CTA() {
  const [showTrialInfo, setShowTrialInfo] = useState(false);

  return (
    <section className="relative py-24 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-primary-500 to-secondary-500" />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <h2 className="text-3xl md:text-4xl font-extralight text-gray-900 dark:text-white mb-3">
            Ready to Transform Your Operations?
          </h2>
          <p className="text-xl font-light text-gray-600 dark:text-white mb-8">
            Elevate your operations with cutting-edge AI solutions
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <div className="relative">
            <Link to="/trial">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 rounded-full bg-primary-500 text-white font-light shadow-lg shadow-primary-500/25 hover:shadow-primary-500/35 transition-all duration-300"
                onMouseEnter={() => setShowTrialInfo(true)}
                onMouseLeave={() => setShowTrialInfo(false)}
              >
                Start Free Trial
              </motion.button>
              </Link>
              {showTrialInfo && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="absolute top-full left-0 mt-2 whitespace-nowrap text-sm text-white font-light"
                >
                  Start your 14-day trial today. No credit card required.
                </motion.div>
              )}
            </div>
            <Link to="/demo">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-4 rounded-full bg-white dark:bg-white text-primary-500 font-light shadow-lg hover:shadow-xl transition-all duration-300"
            >
              Schedule Demo
            </motion.button>
            </Link>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
