import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import DashboardPreview from '../components/DashboardPreview';
import CTA from '../components/CTA';
import Product from '../components/Product';
import Pricing from '../components/Pricing';


export default function Home() {
  return (
    <>
      <Helmet>
        <title>OPEXAI - AI-Driven Analytics & Automation Platform</title>
        <meta name="description" content="Transform your business operations with OPEXAI's AI-powered analytics and automation platform. Get real-time insights and optimize your business processes." />
        <meta name="keywords" content="AI analytics, business automation, operational excellence, machine learning, business intelligence, OPEXAI" />
        <meta property="og:title" content="OPEXAI - AI-Driven Analytics & Automation" />
        <meta property="og:description" content="Revolutionize your business operations with AI-powered analytics and automation." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://opexai.io" />
      </Helmet>    
      <Hero />
      <DashboardPreview />
      <Product />
      <Pricing />
      <CTA />
</>
  );
}
