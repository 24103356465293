import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import { useState, useEffect } from 'react';

import {
  GlobeAltIcon,
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  WindowIcon,
  CogIcon
} from '@heroicons/react/24/outline';

const integrationFeatures = [
  {
    title: "Browser Extension",
    icon: GlobeAltIcon,
    description: "Directly access OpexAI insights and automation while browsing. Our extension connects to CRM, ERP, and other business tools, surfacing client and operational data in real-time as you work.",
    features: [
      {
        title: "Real-Time Data",
        description: "Get immediate insights and key client metrics as you work within your browser"
      },
      {
        title: "One-Click Actions",
        description: "Retrieve information on clients, prospects, or ongoing tasks without switching tabs"
      },
      {
        title: "Enhanced Productivity",
        description: "Streamlines workflows by connecting your CRM, accounting, and project tools in one interface"
      },
      {
        title: "Smart Recommendations",
        description: "Context-aware suggestions for improved decision making"
      },
      {
        title: "Automated Data Capture",
        description: "Intelligent information gathering from web sources"
      },
      {
        title: "Custom Workflows",
        description: "Build and customize your own automation rules"
      }
    ]
  },
  {
    title: "Automation Engine",
    icon: CogIcon,
    description: "Our Automation Engine provides integration with client-specific tools, connecting systems across finance, HR, project management, and more, allowing OpexAI to drive automation and streamline complex workflows.",
    features: [
      {
        title: "Custom Workflow Automation",
        description: "Tailor workflows across connected tools to ensure operational efficiency"
      },
      {
        title: "Intelligent Triggering",
        description: "Set automation rules based on real-time data insights and events"
      },
      {
        title: "Comprehensive Connectivity",
        description: "Connect with both common and proprietary tools"
      },
      {
        title: "Process Optimization",
        description: "AI-driven workflow improvements"
      },
      {
        title: "Error Handling",
        description: "Automated error detection and resolution"
      },
      {
        title: "Performance Analytics",
        description: "Track and optimize automation efficiency"
      }
    ]
  },
  {
    title: "Slack Integration",
    icon: ChatBubbleBottomCenterTextIcon,
    description: "Bring OpexAI's intelligence into your Slack workspace to streamline team communication, task updates, and actionable alerts for enhanced collaboration.",
    features: [
      {
        title: "Automated Alerts",
        description: "Receive notifications on workflow updates, client insights, and operational tasks"
      },
      {
        title: "Quick Queries",
        description: "Ask OpexAI questions or retrieve data directly within Slack"
      },
      {
        title: "Seamless Updates",
        description: "Stay on top of projects, KPIs, and alerts without leaving Slack"
      },
      {
        title: "Team Collaboration",
        description: "Enhanced group productivity features"
      },
      {
        title: "Data Sharing",
        description: "Easy information distribution across channels"
      },
      {
        title: "Custom Notifications",
        description: "Tailored alert settings for teams"
      }
    ]
  },
  {
    title: "Google Email Integration",
    icon: EnvelopeIcon,
    description: "OpexAI integrates with Google Email to surface client insights, tasks, and essential information directly within your inbox, making it easier to make informed decisions without switching platforms.",
    features: [
      {
        title: "Client Insights on Email Open",
        description: "See a 360° view of clients as soon as you open an email"
      },
      {
        title: "Automated Responses",
        description: "Draft and send responses using data-driven recommendations"
      },
      {
        title: "Seamless CRM Integration",
        description: "Sync email actions with CRM updates"
      },
      {
        title: "Smart Categorization",
        description: "AI-powered email organization"
      },
      {
        title: "Follow-up Automation",
        description: "Intelligent follow-up scheduling"
      },
      {
        title: "Data Extraction",
        description: "Automated information capture from emails"
      }
    ]
  },
  {
    title: "Google Chat Integration",
    icon: ChatBubbleBottomCenterTextIcon,
    description: "Enable your team to stay updated with OpexAI insights and recommendations through Google Chat, keeping collaboration and productivity at the forefront.",
    features: [
      {
        title: "Real-Time Notifications",
        description: "Receive alerts on critical updates directly in Google Chat"
      },
      {
        title: "Automated Interactions",
        description: "Ask questions or request insights from OpexAI"
      },
      {
        title: "Continuous Updates",
        description: "Keep the team informed without leaving Google Chat"
      },
      {
        title: "Task Management",
        description: "Create and assign tasks directly in chat"
      },
      {
        title: "Meeting Scheduling",
        description: "Coordinate meetings with AI assistance"
      },
      {
        title: "Resource Sharing",
        description: "Quick access to documents and data"
      }
    ]
  },
  {
    title: "Microsoft Teams Integration",
    icon: WindowIcon,
    description: "Unify your data insights and action items directly within Microsoft Teams to keep workflows smooth and communication clear.",
    features: [
      {
        title: "Integrated Task Management",
        description: "Access, assign, and monitor tasks directly within Teams"
      },
      {
        title: "AI-Driven Alerts",
        description: "Receive real-time insights and recommendations"
      },
      {
        title: "Direct Data Access",
        description: "View client data and insights directly in Teams"
      },
      {
        title: "Channel Integration",
        description: "Automated updates in relevant channels"
      },
      {
        title: "Meeting Intelligence",
        description: "AI-powered meeting summaries and action items"
      },
      {
        title: "Workflow Automation",
        description: "Streamlined processes within Teams"
      }
    ]
  }
];

function FeatureCarousel({ features }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const itemsPerPage = 3;
  const totalPages = Math.ceil(features.length / itemsPerPage);
  
  useEffect(() => {
    if (!isPaused) {
      const timer = setInterval(() => {
        setCurrentPage((prev) => (prev + 1) % totalPages);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [isPaused, totalPages]);

  return (
    <div 
      className="relative overflow-hidden"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentPage}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          className="space-y-6"
        >
          {features
            .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
            .map((feature) => (
              <div key={feature.title} className="flex items-start">
                <BoltIcon className="h-6 w-6 text-primary-500" />
                <div className="ml-4">
                  <h3 className="text-white font-light">{feature.title}</h3>
                  <p className="text-gray-400 font-light">{feature.description}</p>
                </div>
              </div>
            ))}
        </motion.div>
      </AnimatePresence>
      <div className="flex justify-center mt-6 space-x-2">
        {Array.from({ length: totalPages }).map((_, idx) => (
          <button
            key={idx}
            onClick={() => setCurrentPage(idx)}
            className={`w-2 h-2 rounded-full transition-all ${
              currentPage === idx ? 'bg-primary-500 w-4' : 'bg-gray-600'
            }`}
          />
        ))}
      </div>
    </div>
  );
}

export default function IntegrationsPage() {
  return (
    <>
      <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 mb-12">
        <Helmet>
          <title>Integrations | OPEXAI - AI-Driven Analytics & Automation</title>
          <meta name="description" content="Explore OPEXAI's powerful integrations with popular business tools. Streamline your workflows with AI-powered automation and insights." />
          <meta name="keywords" content="integrations, automation, business tools, AI analytics, workflow optimization, OPEXAI" />
          <meta property="og:title" content="Integrations | OPEXAI" />
          <meta property="og:description" content="Connect your essential tools with OPEXAI's AI-powered platform for enhanced productivity and insights." />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://opexai.io/integrations" />
        </Helmet>

        <PageHero 
          subtitle="Seamless Connections"
          title="Power Your Workflow with"
          highlightedText="Integrations"
          description="Connect OpexAI with your essential tools to create a unified, intelligent operational ecosystem."
        />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {integrationFeatures.map((integration, index) => (
            <motion.div
              key={integration.title}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className={`grid lg:grid-cols-2 gap-12 items-center ${
                index !== 0 ? 'mt-24' : ''
              }`}
            >
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className={index % 2 === 0 ? 'order-1' : 'order-2'}
              >
                <h2 className="text-4xl font-light text-white mb-6">
                  {integration.title}
                  <span className="block text-lg text-primary-400 mt-2">
                    Enhanced Productivity Through Integration
                  </span>
                </h2>
                <p className="text-gray-300 font-light mb-8">
                  {integration.description}
                </p>
                <FeatureCarousel features={integration.features} />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className={`relative ${index % 2 === 0 ? 'order-2' : 'order-1'}`}
              >
                <div className="bg-gradient-to-r from-primary-500/20 to-secondary-500/20 rounded-2xl p-1">
                  <div className="bg-gray-800 rounded-2xl p-8">
                    <div className="aspect-video rounded-lg bg-gray-900/50 backdrop-blur-xl flex items-center justify-center">
                      <integration.icon className="h-24 w-24 text-primary-500/50" />
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}
