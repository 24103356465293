import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';

export default function Terms() {
  return (
    <>
      <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 mb-12">
        <Helmet>
          <title>Terms & Conditions | OPEXAI - AI-Driven Analytics & Automation</title>
          <meta name="description" content="OPEXAI's terms and conditions for using our AI analytics and automation services. Learn about your rights and responsibilities." />
          <meta name="keywords" content="terms and conditions, user agreement, service terms, AI analytics, OPEXAI" />
          <meta property="og:title" content="Terms & Conditions | OPEXAI" />
          <meta property="og:description" content="Read OPEXAI's terms and conditions for using our AI-driven analytics and automation platform." />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://opexai.io/terms" />
        </Helmet>
        
        <PageHero 
          subtitle="Last updated: January 2024"
          title="Terms & Conditions"
        />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div id="terms-content" className="prose dark:prose-invert prose-lg max-w-none">
            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Agreement to Terms</h2>
              <p className="text-gray-300 mb-4">By accessing and using OPEXAI's website and services, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not access our services.</p>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Intellectual Property</h2>
              <p className="text-gray-300 mb-4">The website and its original content, features, and functionality are owned by OPEXAI LTD and are protected by international copyright, trademark, and other intellectual property laws.</p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>All content is the property of OPEXAI LTD</li>
                <li>Unauthorized use or reproduction is prohibited</li>
                <li>Our trademarks may not be used without written permission</li>
              </ul>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">User Responsibilities</h2>
              <p className="text-gray-300 mb-4">When using our services, you agree to:</p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Provide accurate and complete information</li>
                <li>Maintain the security of your account</li>
                <li>Use the services in compliance with applicable laws</li>
                <li>Not engage in any unauthorized or harmful activities</li>
                <li>Not attempt to bypass any security measures</li>
              </ul>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Service Usage</h2>
              <div className="space-y-8">
                <div>
                  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Acceptable Use</h3>
                  <ul className="list-disc pl-6 text-gray-300 space-y-2">
                    <li>Use services for intended business purposes only</li>
                    <li>Respect system resources and other users</li>
                    <li>Follow security best practices</li>
                    <li>Report any security vulnerabilities</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-extralight mb-6 text-white border-b border-gray-700/50 pb-2">Prohibited Activities</h3>
                  <ul className="list-disc pl-6 text-gray-300 space-y-2">
                    <li>Unauthorized access or hacking attempts</li>
                    <li>Distribution of malware or harmful code</li>
                    <li>Interference with service operation</li>
                    <li>Violation of any applicable laws</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Limitation of Liability</h2>
              <p className="text-gray-300 mb-4">OPEXAI LTD shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our services.</p>
              <div className="bg-gray-800 p-6 rounded-xl mt-4">
                <p className="text-gray-300">Our services are provided "as is" without any warranty or condition, express, implied or statutory.</p>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Changes to Terms</h2>
              <p className="text-gray-300 mb-4">We reserve the right to modify these terms at any time. We will notify users of any material changes by posting the new Terms on this page.</p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Changes become effective immediately upon posting</li>
                <li>Continued use constitutes acceptance of new terms</li>
                <li>Regular review of terms is recommended</li>
              </ul>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Governing Law</h2>
              <p className="text-gray-300 mb-4">These terms shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions.</p>
              <div className="bg-gray-800 p-6 rounded-xl mt-4">
                <p className="text-gray-300">Any disputes relating to these terms will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
              </div>
            </section>

            <section className="mb-16">
              <h2 className="text-2xl font-extralight mb-8 text-white border-b border-gray-700 pb-2">Contact Information</h2>
              <p className="text-gray-300 mb-4">For any questions about these Terms, please contact us:</p>
              <div className="bg-gray-800 p-6 rounded-xl mt-4">
                <p className="font-light text-gray-300">
                  OPEXAI LTD<br />
                  5 Technology Park<br />
                  Colindeep Lane<br />
                  Colindale, London<br />
                  NW9 6BX<br />
                  United Kingdom
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
