import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import PageHero from '../components/PageHero';
import FutureSuggestionsForm from '../components/FutureSuggestionsForm';

import {
  BeakerIcon,
  RocketLaunchIcon,
  GlobeAltIcon,
  SparklesIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';

const phases = [
  {
    title: "Current Solutions",
    icon: BeakerIcon,
    status: "Available Now",
    timeframe: "Q4 2024",
    description: "Foundation of AI-driven analytics and automation",
    features: [
      {
        title: "Overall Onboarding",
        status: "Released",
        description: "Efficient onboarding experience with customized company-specific settings"
      },
      {
        title: "Company Dashboard",
        status: "Released",
        description: "High-level operational performance view with KPIs and predictive analytics"
      },
      {
        title: "Browser Extension",
        status: "Released",
        description: "Direct access to OpexAI's insights with real-time data integration"
      },
      {
        title: "Copilot",
        status: "Released",
        description: "AI-powered agents offering real-time recommendations and automation"
      }
    ]
  },
  {
    title: "Expanding Operational Excellence",
    icon: RocketLaunchIcon,
    status: "In Development",
    timeframe: "Q1 - Q2 2025",
    description: "Enhanced capabilities and department-specific features",
    features: [
      {
        title: "AI Agent Expansion",
        status: "In Development",
        description: "Specialized AI agents for different departments"
      },
      {
        title: "Department Dashboards",
        status: "In Development",
        description: "Customized dashboards for department-specific KPIs and goals"
      },
      {
        title: "Automated Workflow Suggestions",
        status: "In Development",
        description: "Intelligent recommendations for workflow optimizations"
      }
    ]
  },
  {
    title: "Advanced AI and Automation",
    icon: SparklesIcon,
    status: "Coming Soon",
    timeframe: "Q3 - Q4 2025",
    description: "Advanced AI capabilities and automation features",
    features: [
      {
        title: "Predictive Analytics and Risk Management",
        status: "Planned",
        description: "Enhanced forecasting and risk mitigation capabilities"
      },
      {
        title: "Custom Automation Workflows",
        status: "Planned",
        description: "Build and deploy custom workflows across connected tools"
      },
      {
        title: "Self-Governing System Administration",
        status: "Planned",
        description: "Auto-management capabilities for optimal performance"
      }
    ]
  },
  {
    title: "Enhanced Integrations",
    icon: GlobeAltIcon,
    status: "Future Release",
    timeframe: "Early 2026",
    description: "Expanded platform capabilities and customization",
    features: [
      {
        title: "Expanded Integration Capabilities",
        status: "Planned",
        description: "Additional integrations for common and client-specific tools"
      },
      {
        title: "User Customization Options",
        status: "Planned",
        description: "Advanced settings for dashboard and report customization"
      },
      {
        title: "Global Scale",
        status: "Planned",
        description: "Worldwide infrastructure and support"
      }
    ],
    footer: {
        text: "100+ Additional Features in Pipeline",
        subtext: "Continuously evolving based on industry needs and technological advancements"
      }
  }
];

function RoadmapPhase({ phase, index }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.2 }}
      className="relative"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-secondary-500/10 rounded-2xl blur-xl"></div>
      <div className="relative bg-gray-800/40 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50">
        <div className="flex items-center gap-4 mb-6">
          <phase.icon className="h-8 w-8 text-primary-500" />
          <div>
            <h3 className="text-2xl font-light text-white">{phase.title}</h3>
            <span className="text-primary-400 text-sm">{phase.timeframe}</span>
          </div>
          <span className={`ml-auto px-3 py-1 rounded-full text-xs font-light ${
            phase.status === 'Available Now' 
              ? 'bg-green-500/10 text-green-400'
              : phase.status === 'In Development'
              ? 'bg-primary-500/10 text-primary-400'
              : 'bg-gray-500/10 text-gray-400'
          }`}>
            {phase.status}
          </span>
        </div>
        
        <p className="text-gray-300 font-light mb-6">{phase.description}</p>
        
        <div className="space-y-4">
          {phase.features.map((feature) => (
            <div key={feature.title} className="flex items-start gap-3">
              <ChartBarIcon className="h-5 w-5 text-primary-500 mt-1" />
              <div>
                <div className="flex items-center gap-2">
                  <h4 className="text-white font-light">{feature.title}</h4>
                  <span className={`px-2 py-0.5 rounded-full text-xs ${
                    feature.status === 'Released' 
                      ? 'bg-green-500/10 text-green-400'
                      : feature.status === 'In Development'
                      ? 'bg-yellow-500/10 text-yellow-400'
                      : 'bg-gray-500/10 text-gray-400'
                  }`}>
                    {feature.status}
                  </span>
                </div>
                <p className="text-gray-400 font-light text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}

export default function RoadmapPage() {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Helmet>
        <title>Product Roadmap | OPEXAI - AI-Driven Analytics & Automation</title>
        <meta name="description" content="Explore OPEXAI's product roadmap and upcoming features. See what we're building and contribute to our development journey." />
        <meta property="og:title" content="Product Roadmap | OPEXAI" />
        <meta property="og:description" content="Discover what's next for OPEXAI's AI-powered analytics and automation platform." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://opexai.io/roadmap" />
      </Helmet>

      <PageHero 
        subtitle="Development Journey"
        title="Building the Future of"
        highlightedText="Business Operations"
        description="Follow our development journey and see what's coming next in AI-powered business automation."
      />

<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
  <div className="grid gap-8 lg:grid-cols-2">
    {phases.map((phase, index) => (
      <RoadmapPhase key={phase.title} phase={phase} index={index} />
    ))}
  </div>
  
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="mt-16 text-center"
  >
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-secondary-500/10 rounded-2xl blur-xl"></div>
      <div className="relative bg-gray-800/40 backdrop-blur-xl rounded-2xl p-8 border border-gray-700/50">
        <h3 className="text-3xl font-light text-transparent bg-clip-text bg-gradient-to-r from-primary-400 via-secondary-400 to-primary-400">
          100+ Features in Development Pipeline
        </h3>
        <p className="text-gray-300 font-light mt-4">
          Our roadmap continues to evolve with industry needs and technological advancements
        </p>
      </div>
    </div>
  </motion.div>

  <div className="mt-24">
  <FutureSuggestionsForm />
</div>
      </div>
    </div>
  );
}
