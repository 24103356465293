import { useState } from 'react';
import { motion } from 'framer-motion';
import HubSpotForm from './ui/HubSpotForm';
import GoogleAppointment from './ui/GoogleAppointment';
import { CONFIG } from '../config/env';


export default function Contact() {
  const [activeTab, setActiveTab] = useState('calendar');
  
  const tabs = [
    { id: 'calendar', label: 'Book a Meeting' },
    { id: 'form', label: 'Contact Us' }
  ];
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8"
    >
      <div className="flex space-x-4 mb-8 border-b border-gray-200 dark:border-gray-700">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`pb-4 px-4 text-sm font-light transition-colors duration-200 ${
              activeTab === tab.id
                ? 'text-primary-500 border-b-2 border-primary-500'
                : 'text-gray-500 dark:text-gray-400 hover:text-primary-500'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {activeTab === 'calendar' ? (
        <GoogleAppointment />
      ) : (
        <HubSpotForm
          formId={CONFIG.hubspot.contactFormId}
          fields={[
            { name: 'email', label: 'Email', type: 'email', required: true },
            { name: 'firstname', label: 'Name', required: true },
            { name: 'company', label: 'Company' },
            { name: 'message', label: 'Message', type: 'textarea' }
          ]}
          submitButtonText="Send Message"
        />
      )}
    </motion.div>
  );
}
